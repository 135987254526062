import React, { useMemo } from "react";
import { Grid, HStack, VStack } from "@chakra-ui/react";
import { UsagePackageType } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import {
    Alpha2Code,
    CobiraWorldChart,
    DataPackageAllowance,
    Duration,
    getCountry,
    Paper,
    PlaceholderText,
    SmsPackageAllowance,
    TextColumn,
} from "@cobira/ui-library";
import { UsagePackageActivationTypeLabels } from "../../../../labels/UsagePackageActivationTypeLabels";
import { dataAllowance, smsAllowance } from "../../../../utils/usagePackageTypeUtils";
import DeletedAt from "../../../../components/DeletedAt/DeletedAt";

export interface UsagePackageTypeDetailsProps {
    query: UseQueryResult<UsagePackageType>;
}

const UsagePackageTypeDetails = ({ query: { data: usagePackageType, isLoading } }: UsagePackageTypeDetailsProps) => {
    const dataUsageAllowance = dataAllowance(usagePackageType?.usageAllowances || []);
    const smsUsageAllowance = smsAllowance(usagePackageType?.usageAllowances || []);
    const countries = useMemo(
        () => usagePackageType?.supportedCountries?.map(countryCode => getCountry(countryCode as Alpha2Code)) || [],
        [usagePackageType],
    );
    return (
        <VStack w={"100%"} align={"stretch"} gap={6}>
            <Paper header={"Details"} withDivider>
                <Grid templateColumns="auto auto" gap="4">
                    <TextColumn heading="Name" grid isLoading={isLoading}>
                        {usagePackageType?.customName}
                    </TextColumn>
                    <TextColumn heading="Description" grid isLoading={isLoading}>
                        <PlaceholderText text={usagePackageType?.description} />
                    </TextColumn>
                    <TextColumn heading="Duration" grid isLoading={isLoading}>
                        <Duration value={usagePackageType?.packageDuration} />
                    </TextColumn>
                    <TextColumn heading="Activation Behaviour" grid isLoading={isLoading}>
                        <PlaceholderText
                            text={UsagePackageActivationTypeLabels[usagePackageType?.activationType || "UNKNOWN"]}
                        />
                    </TextColumn>
                    <TextColumn heading="Data Allowance" grid isLoading={isLoading}>
                        <DataPackageAllowance
                            allowance={dataUsageAllowance?.allowanceAmount}
                            enabled={dataUsageAllowance?.enabled}
                        />
                    </TextColumn>
                    <TextColumn heading="SMS Allowance" grid isLoading={isLoading}>
                        <SmsPackageAllowance
                            allowance={smsUsageAllowance?.allowanceAmount}
                            enabled={smsUsageAllowance?.enabled}
                        />
                    </TextColumn>
                    <TextColumn heading="Deleted At" grid isLoading={isLoading}>
                        <DeletedAt deletedAt={usagePackageType?.deletedAt} />
                    </TextColumn>
                </Grid>
            </Paper>
            <HStack w={"70%"} align={"stretch"}>
                <Paper header={"Countries"} withDivider cardProps={{ w: "100%" }}>
                    <CobiraWorldChart
                        countries={countries}
                        width={"100%"}
                        height={"300px"}
                        countryListOptions={{ show: true, columns: 3 }}
                    />
                </Paper>
            </HStack>
        </VStack>
    );
};

export default UsagePackageTypeDetails;
