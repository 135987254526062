import { rangeInput, singleInput, usePersistedSearch } from "@cobira/ui-library";
import { BillingGroup, NetworkState } from "../api";
import { NetworkStateLabels } from "../labels/NetworkStateLabels";

export interface UseSimCardSearchProps {
    onChange?: () => void;
}

const useSimCardSearch = ({ onChange }: UseSimCardSearchProps) => {
    return usePersistedSearch({
        persistenceKey: "sim-card-table-search",
        config: {
            search: singleInput<string>("Search"),
            msisdnSearch: singleInput<string>("MSISDN Search"),
            iccRange: rangeInput<string>("ICC Range"),
            billingGroup: singleInput<BillingGroup>("Billing Group", { display: value => value.name }),
            simCardState: singleInput<NetworkState>("SIM Card State", { display: value => NetworkStateLabels[value] }),
        },
        onChange: onChange,
    });
};

export default useSimCardSearch;
