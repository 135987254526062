import { NetworkCredential } from "../../../../api";
import { ImeiLockList, ImeiValueType, Paper, PhoneNumberList, PlaceholderText, TextColumn } from "@cobira/ui-library";
import { Flex, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import { useApi } from "../../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { ImeiLockStateLabels } from "../../../../labels/ImeiLockStateLabels";
import { ImeiLockViolationBehaviourLabels } from "../../../../labels/ImeiLockLockViolationBehaviourLabels";

export interface NetworkCredentialDetailsProps {
    networkCredential?: NetworkCredential;
}

const NetworkCredentialDetails = ({ networkCredential }: NetworkCredentialDetailsProps) => {
    const { networkCredentialApi } = useApi();
    const { data: networkCredentialSettings, isLoading: isLoadingNetworkCredentialSettings } = useQuery(
        ["networkcredentials", networkCredential?.imsi, "settings"],
        () => networkCredentialApi.getNetworkCredentialSettings({ imsi: networkCredential?.imsi || "" }),
        { enabled: !!networkCredential?.imsi },
    );
    const { data: networkCredentialImeiLocks, isFetching: isFetchingImeiLocks } = useQuery(
        ["networkcredentials", networkCredential?.imsi, "imeilocks"],
        () => networkCredentialApi.getNetworkCredentialImeiLocks({ imsi: networkCredential?.imsi || "" }),
        { enabled: !!networkCredential?.imsi },
    );
    const { data: phoneNumbers, isFetching: isFetchingPhoneNumbers } = useQuery(
        ["networkcredentials", networkCredential?.imsi, "phonenumbers"],
        () => networkCredentialApi.getNetworkCredentialPhoneNumbers({ imsi: networkCredential?.imsi || "" }),
        { enabled: !!networkCredential?.imsi },
    );

    const renderContent = () => {
        if (!networkCredential) {
            return <Text>Please Select a Network Credential</Text>;
        }

        return (
            <SimpleGrid columns={3} width={"100%"}>
                <Flex direction={"column"}>
                    <TextColumn heading="IMEI Lock State" isLoading={isLoadingNetworkCredentialSettings} mt={2}>
                        <PlaceholderText
                            text={ImeiLockStateLabels.getLabel(networkCredentialSettings?.imeiLockState)}
                        />
                    </TextColumn>
                    <TextColumn
                        mt={2}
                        heading={"IMEI Lock Violation Behaviour"}
                        isLoading={isLoadingNetworkCredentialSettings}
                    >
                        <PlaceholderText
                            text={ImeiLockViolationBehaviourLabels.getLabel(
                                networkCredentialSettings?.imeiLockViolationBehaviour,
                            )}
                        />
                    </TextColumn>
                </Flex>
                <Flex direction={"column"}>
                    <TextColumn heading={"Configured IMEIs"}>
                        <ImeiLockList
                            isLoading={isLoadingNetworkCredentialSettings || isFetchingImeiLocks}
                            imeiLocks={networkCredentialImeiLocks?.map(imeiLock => ({
                                allowedImei: imeiLock.allowedImeiValue,
                                allowedImeiType: imeiLock.imeiValueType as ImeiValueType,
                            }))}
                        />
                    </TextColumn>
                </Flex>
                <Flex direction={"column"}>
                    <TextColumn heading={"Phone Numbers"}>
                        <PhoneNumberList
                            isLoading={isLoadingNetworkCredentialSettings || isFetchingPhoneNumbers}
                            phoneNumbers={phoneNumbers}
                        />
                    </TextColumn>
                </Flex>
            </SimpleGrid>
        );
    };

    return (
        <Paper header="Details" withDivider>
            {renderContent()}
        </Paper>
    );
};

export default NetworkCredentialDetails;
