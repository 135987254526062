/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ImeiValueType = {
    Tac: 'TAC',
    Full: 'FULL'
} as const;
export type ImeiValueType = typeof ImeiValueType[keyof typeof ImeiValueType];


export function ImeiValueTypeFromJSON(json: any): ImeiValueType {
    return ImeiValueTypeFromJSONTyped(json, false);
}

export function ImeiValueTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImeiValueType {
    return json as ImeiValueType;
}

export function ImeiValueTypeToJSON(value?: ImeiValueType | null): any {
    return value as any;
}

