/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2024*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImeiValueType } from './ImeiValueType';
import {
    ImeiValueTypeFromJSON,
    ImeiValueTypeFromJSONTyped,
    ImeiValueTypeToJSON,
} from './ImeiValueType';

/**
 * An IMEI lock may represent a TAC or the full IMEI (singular device) that it locks to.
 * @export
 * @interface ImeiLock
 */
export interface ImeiLock {
    /**
     * 
     * @type {string}
     * @memberof ImeiLock
     */
    allowedImeiValue: string;
    /**
     * 
     * @type {ImeiValueType}
     * @memberof ImeiLock
     */
    imeiValueType: ImeiValueType;
}

/**
 * Check if a given object implements the ImeiLock interface.
 */
export function instanceOfImeiLock(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "allowedImeiValue" in value;
    isInstance = isInstance && "imeiValueType" in value;

    return isInstance;
}

export function ImeiLockFromJSON(json: any): ImeiLock {
    return ImeiLockFromJSONTyped(json, false);
}

export function ImeiLockFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImeiLock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedImeiValue': json['allowedImeiValue'],
        'imeiValueType': ImeiValueTypeFromJSON(json['imeiValueType']),
    };
}

export function ImeiLockToJSON(value?: ImeiLock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedImeiValue': value.allowedImeiValue,
        'imeiValueType': ImeiValueTypeToJSON(value.imeiValueType),
    };
}

