import React, { useEffect } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ControlledAutoCompleteSingleSelectInput, FormContainer, FormField } from "@cobira/ui-library";
import { Button, ButtonGroup, Input } from "@chakra-ui/react";
import { ImeiLockUpdateBehaviour, ImeiValueType } from "../../api";
import { ImeiLockUpdateBehaviourLabels } from "../../labels/ImeiLockUpdateBehaviourLabels";
import { ImeiValueTypeLabels } from "../../labels/ImeiValueTypeLabels";

const ImeiLockFormSchema = z.object({
    imeiValueType: z.nativeEnum(ImeiValueType),
    imeiTac: z
        .string()
        .min(1, "TAC is required")
        .min(8, "TAC must be 8 digits long")
        .max(8, "TAC must be 8 digits long")
        .refine(arg => Number.isInteger(Number.parseInt(arg)), "Only digits allowed")
        .optional(),
    imei: z
        .string()
        .min(1, "The IMEI number is required")
        .min(14, "IMEI Number must be at least 14 digits")
        .max(16, "IMEI cannot exceed 16 digits")
        .refine(arg => Number.isInteger(Number.parseInt(arg)), "Only digits allowed")
        .optional(),
    operation: z.nativeEnum(ImeiLockUpdateBehaviour),
});

export type ImeiLockFormType = z.infer<typeof ImeiLockFormSchema>;

export interface ImeiLockFormProps {
    onSubmit: (form: ImeiLockFormType) => void;
    onCancel?: () => void;
}

const ImeiLockForm = ({ onSubmit, onCancel }: ImeiLockFormProps) => {
    const {
        formState: { errors },
        handleSubmit,
        control,
        register,
        watch,
        setValue,
    } = useForm<ImeiLockFormType>({
        resolver: zodResolver(ImeiLockFormSchema),
        defaultValues: {
            imeiValueType: ImeiValueType.Full,
            operation: ImeiLockUpdateBehaviour.Create,
        },
    });

    const imeiValueType = watch("imeiValueType");
    useEffect(() => {
        if (imeiValueType === ImeiValueType.Full) {
            setValue("imeiTac", undefined);
        } else {
            setValue("imei", undefined);
        }
    }, [imeiValueType, setValue]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
                <FormField label={"Operation"} error={errors?.operation}>
                    <ControlledAutoCompleteSingleSelectInput
                        input={{
                            items: Object.values(ImeiLockUpdateBehaviour),
                            displayMapping: value => ImeiLockUpdateBehaviourLabels[value],
                            autocompleteAbleMapping: value => ImeiLockUpdateBehaviourLabels[value],
                            placeholder: "Choose operation",
                        }}
                        control={{
                            name: "operation",
                            control: control,
                        }}
                    />
                </FormField>
                <FormField label={"Type"} error={errors?.imeiValueType}>
                    <ControlledAutoCompleteSingleSelectInput
                        input={{
                            items: Object.values(ImeiValueType),
                            displayMapping: value => ImeiValueTypeLabels[value],
                            autocompleteAbleMapping: value => ImeiValueTypeLabels[value],
                            placeholder: "Choose IMEI input type",
                        }}
                        control={{
                            name: "imeiValueType",
                            control: control,
                        }}
                    />
                </FormField>
                {imeiValueType === ImeiValueType.Full && (
                    <FormField label={"IMEI"} error={errors?.imei}>
                        <Input {...register("imei")} type={"number"} />
                    </FormField>
                )}
                {imeiValueType === ImeiValueType.Tac && (
                    <FormField label={"IMEI TAC"} error={errors?.imeiTac}>
                        <Input {...register("imeiTac")} type={"number"} />
                    </FormField>
                )}
            </FormContainer>
            <ButtonGroup mt={4} gap={"2"} w={"100%"}>
                <Button type={"submit"} ml={"auto"}>
                    Confirm
                </Button>
                {onCancel && (
                    <Button onClick={onCancel} variant={"ghost"}>
                        Cancel
                    </Button>
                )}
            </ButtonGroup>
        </form>
    );
};

export default ImeiLockForm;
